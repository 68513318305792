import useIndividualProductData from '@ecom/composables/product/useIndividualProductData'
import type { IndividualDataMinimalProduct } from '@ecom/stores/individual.types'
import { getProductBaseFinalPrice } from '../useProductPrice/useProductPrice.helpers'
import useProductQuantity from '../useProductQuantity'
import type { TierPrice } from '#gql'

interface ValidPriceTier extends TierPrice {
  quantity: number
  final_price: {
    value: number
  }
}

export interface UnitPriceTier {
  quantity: number
  price: number
}

export function useProductPriceTiers(product: IndividualDataMinimalProduct) {
  const { getProductPriceTiers } = useIndividualProductData()

  const finalPrice = getProductBaseFinalPrice(product) ?? 0
  const { productQuantityData } = useProductQuantity(product)

  const minQuantity = computed(() => productQuantityData.value?.min_sale_qty ?? 1)

  /**
   * List of valid price tiers containing both quantity and price
   */
  const priceTiers = computed<UnitPriceTier[]>(() => {
    const tiers = getProductPriceTiers(product)
      .filter(tier => Boolean(tier && tier.quantity && tier.final_price?.value)) as ValidPriceTier[]

    const sortedValidTiers = tiers.map((tier) => {
      const pricePerUnit = tier.final_price.value / tier.quantity
      return {
        quantity: tier.quantity,
        price: pricePerUnit,
      }
    }).sort((a, b) => a.quantity - b.quantity)

    if (!sortedValidTiers.length || sortedValidTiers[0].quantity > minQuantity.value) {
      sortedValidTiers.unshift({ quantity: minQuantity.value, price: finalPrice })
    }

    // If few price tiers have the same price - leave only the first occurrence
    return sortedValidTiers.filter((tier, index, self) =>
      index === self.findIndex(t => t.price === tier.price),
    )
  })

  /**
   * List of valid price tiers that are lower than the final price
   * for that product
   */
  const applicablePriceTiers = computed(() => {
    return priceTiers.value
      .filter(tier => tier.price <= finalPrice)
  })

  /**
   * Returns a unit price for the product with given quantity
   * based on price tiers for that product
   */
  const getUnitPriceForQuantity = (quantity: number) => {
    return applicablePriceTiers.value.reduce((price, tier) => {
      if (quantity < tier.quantity) { return price }
      if (tier.price > price) { return price }
      return tier.price
    }, finalPrice)
  }

  /**
   * Returns a total price for the product with given quantity
   * based on price tiers for that product
   */
  const getTotalPriceForQuantity = (quantity: number) => {
    return getUnitPriceForQuantity(quantity) * quantity
  }

  return {
    priceTiers,
    applicablePriceTiers,
    getUnitPriceForQuantity,
    getTotalPriceForQuantity,
  }
}
