import { ProductType } from '@ecom/types/product.types'
import useIndividualProductData from './useIndividualProductData'
import type { CustomAttribute } from '#gql'
import type { Maybe, Scalars } from '#gql/default'

export interface ProductQuantityData {
  availability_label: string
  max_sale_qty: number
  min_sale_qty: number
  qty: number
  qty_increments: number
  sku: string
  unit_conversion_data?: {
    sales_um_2?: string
    min_sale_qty?: number | null
    max_sale_qty?: number | null
    qty_increments?: number | null
  }
}

/**
 * Get product quantity data
 * @description Real data only for logged in customers. For guests we are returning default values.
 * @param {ProductInterfaceExtended} product
 */

function useProductQuantity(
  product: {
    rawlplug_attributes?: Maybe<CustomAttribute>[]
    sku?: string | null
    product_typename?: Maybe<Scalars['String']['output']>
  },
) {
  const { getProductQty, getProduct } = useIndividualProductData()
  const { $i18n } = useNuxtApp()
  const { t } = $i18n

  const individualProductQty = computed((): ProductQuantityData | null => {
    if (!product) {
      return null
    }

    const individualQty = getProductQty(product)
    const productData = getProduct(product)

    if (!individualQty) {
      return null
    }

    const unitConversionErpMoq = typeof productData?.erp_moq_2 === 'string'
      ? Number(productData.erp_moq_2)
      : productData?.erp_moq_2

    const unitConversionErpOrderInterval = typeof productData?.erp_order_interval_2 === 'string'
      ? Number(productData.erp_order_interval_2)
      : productData?.erp_order_interval_2

    const minSaleQty = _calculateMinValue(
      {
        min: individualQty?.min_sale_qty,
        increments: individualQty?.qty_increments,
      },
    ) || 1

    const qtyIncrements = individualQty?.qty_increments || 1
    /**
     * API value may differ from calculated value, for example:
     * - qty_icrements field is set to 150
     * - max_sale_qty is set to 10 000
     * - have have to round down, because max value is not valid, based on provided intervals
     */
    const maxSaleQty = Math.floor((individualQty?.max_sale_qty || 999999) / qtyIncrements) * qtyIncrements

    const productQty: ProductQuantityData = {
      availability_label: product?.product_typename === ProductType.VIRTUAL ? t('ecom_you_will_determine_the_execution_date_after_purchase') : (individualQty?.availability_label || ''),
      max_sale_qty: maxSaleQty,
      min_sale_qty: minSaleQty,
      qty: individualQty?.qty || 0,
      qty_increments: qtyIncrements,
      sku: individualQty?.sku || '',
      unit_conversion_data: {
        min_sale_qty: unitConversionErpMoq,
        max_sale_qty: Math.round(maxSaleQty / minSaleQty),
        qty_increments: unitConversionErpOrderInterval,
        sales_um_2: productData?.sales_um_2 ?? '',
      },
    }

    return productQty
  })

  function _calculateMinValue(data: { min?: number | null, increments?: number | null } | null, defaultValue: number | null = 1) {
    if (typeof data?.min === 'number' && typeof data?.increments === 'number') {
      return Math.ceil(data.min / data.increments) * data.increments
    }

    return defaultValue
  }

  // return a ceiled closest value for given quantity
  function getNextValidQuantity(quantity: number) {
    const qtyIncrements = individualProductQty.value?.qty_increments ?? 1
    return Math.ceil(quantity / qtyIncrements) * qtyIncrements
  }

  return {
    getNextValidQuantity,
    productQuantityData: individualProductQty,
  }
}

export default useProductQuantity
