import { storeToRefs } from 'pinia'
import { useIndividualProductDataStore } from '../../stores/individual'

function useIndividualData() {
  const invididualProductDataStore = useIndividualProductDataStore()
  const { customerAliases, customerPrices, productQty, products, _states, pending } = storeToRefs(invididualProductDataStore)
  const {
    getProduct,
    getProductQty,
    getProductAlias,
    getProductIndividualPrice,
    getProductIndividualCustomerPrice,
    getProductPriceTiers,
    fetchIndividualProductData,
  } = invididualProductDataStore

  async function getIndividualProductsData<T extends ({ sku?: string | null } | null)[]>(products: T) {
    if (!Array.isArray(products)) {
      return
    }

    const productsSkus = (products ?? []).map(item => item?.sku ?? '')

    return await fetchIndividualProductData(productsSkus, {
      alias: true,
      price: true,
      qty: true,
    })
  }

  return {
    getProduct,
    getProductQty,
    getProductAlias,
    getProductIndividualPrice,
    getProductIndividualCustomerPrice,
    getIndividualProductsData,
    getProductPriceTiers,
    fetchIndividualProductData,
    customerAliases,
    customerPrices,
    productQty,
    products,
    _states,
    pending,
  }
}

export default useIndividualData
