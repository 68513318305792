import useIndividualProductData from '../useIndividualProductData'
import type { CustomerPricesPrice, IndividualProductPrices, PricesMinimalProduct } from './useProductPrice.types'
import type { Money } from '#gql'

/*
  USER - logged in user
  GUEST - not logged in visitor
  CUSTOMER - term used in pricing - individual data
 */

export function isPriceValid(price?: Money['value']) {
  return typeof price === 'number' && price > 0
}

export function shouldDisplayCustomerPrice(
  customerPrice: NonNullable<CustomerPricesPrice>,
  calculatedMinimumFinalPrice: Money['value'],
  calculatedMinimumRegularPrice: Money['value'],
) {
  return (
    typeof calculatedMinimumFinalPrice === 'number' && customerPrice < calculatedMinimumFinalPrice)
    || (!calculatedMinimumFinalPrice && typeof calculatedMinimumRegularPrice === 'number' && customerPrice < calculatedMinimumRegularPrice
    )
}

/**
 *  Method used when there is no individual customer price (`customerPrices price`), or when
 *  individual customer price is both greather than `final_price` or `regular_price` (or both values are asbent)
 */
export function getProductUserFallbackPrice({ regularPrice, finalPrice }: { regularPrice: Money['value'], finalPrice: Money['value'] }) {
  if (
    typeof regularPrice === 'number'
    && typeof finalPrice === 'number'
    && regularPrice > finalPrice
  ) {
    return finalPrice
  }

  return regularPrice || 0
}

// Always return MSRP for not logged in user
export function getProductGuestFallbackPrice(product: {
  msrp?: Money | null
} | null): number {
  if (!product) {
    return 0
  }
  return product?.msrp?.value ?? 0
}

export function getValidCustomerPrice({ customerPrice, regularPrice, finalPrice }: { customerPrice: CustomerPricesPrice, regularPrice: Money['value'], finalPrice: Money['value'] }) {
  if (shouldDisplayCustomerPrice(customerPrice as number, finalPrice, regularPrice)) {
    return customerPrice
  }
  return getProductUserFallbackPrice({ regularPrice: regularPrice ?? null, finalPrice: finalPrice ?? null })
}

export function getUserMinimumPrices(product: PricesMinimalProduct, individualProductPrices?: IndividualProductPrices) {
  const {
    minimum_price: {
      regular_price: { value: minimumRegularPrice = 0 } = {},
      final_price: { value: minimumFinalPrice = 0 } = {},
    } = {},
  } = product?.price_range || {}

  const customerMinimumRegularPrice = individualProductPrices?.price_range?.minimum_price?.regular_price?.value ?? 0
  /**
   *  - get regular_price from `getCustomerIndividualProductsData` `product.price_range.minimum_price.regular_price.value` if exists
   *  - if above value does not exists, get regular_price from `product` `product.price_range.minimum_price.regular_price.value`
   */
  const calculatedMinimumRegularPrice = customerMinimumRegularPrice || minimumRegularPrice || 0

  const customerMinimumFinalPrice = individualProductPrices?.price_range?.minimum_price?.final_price?.value ?? 0

  /**
   *  - get final_price from `getCustomerIndividualProductsData` `product.price_range.minimum_price.final_price.value` if exists
   *  - if above value does not exists, get final_price from `product` `product.price_range.minimum_price.final_price.value`
   */
  const calculatedMinimumFinalPrice = customerMinimumFinalPrice || minimumFinalPrice || 0

  return {
    customerMinimumRegularPrice,
    calculatedMinimumRegularPrice,
    customerMinimumFinalPrice,
    calculatedMinimumFinalPrice,
  }
}

export function getProductBaseFinalPrice(product: PricesMinimalProduct) {
  const { isLoggedIn } = useCustomer()
  const { getProductIndividualPrice, getProductIndividualCustomerPrice } = useIndividualProductData()

  if (!product) {
    return 0
  }

  if (!isLoggedIn.value) {
    return getProductGuestFallbackPrice(product)
  }

  const productCustomerPrice = getProductIndividualCustomerPrice(product)
  const individualProductPrices = getProductIndividualPrice(product)

  const customerPrice = productCustomerPrice?.price
  const { calculatedMinimumRegularPrice, calculatedMinimumFinalPrice } = getUserMinimumPrices(product, individualProductPrices) || {}

  /**
   *  `customerPrices price` field is taken from `getCustomerIndividualProductsData` query
   *  - if `customerPrices price` field exists as a number grater than 0:
   *    - if customerPrice is less than calculatedMinimumFinalPrice, customerPrice is used as a final price to display
   *    - if there is no calculatedMinimumFinalPrice, check if customerPrice is less than calculatedMinimumRegularPrice, if so customerPrice is used as a final price to display
   *    - if above conditions are not met, use `_getLoggedInUserPricesWhenIndividualPriceAbsent` return value
   *  - if  `customerPrices price` field does not exists/is not a number, use `_getLoggedInUserPricesWhenIndividualPriceAbsent` return value
   */
  if (isPriceValid(customerPrice)) {
    return getValidCustomerPrice({ customerPrice, regularPrice: calculatedMinimumRegularPrice ?? null, finalPrice: calculatedMinimumFinalPrice ?? null })
  }
  return getProductUserFallbackPrice({ regularPrice: calculatedMinimumRegularPrice ?? null, finalPrice: calculatedMinimumFinalPrice ?? null })
}
